import React from "react";
import { Link } from "react-router-dom";

function AboutUsCards(props) {
  const cardStyling = {
    backgroundColor: "#F2C232",
    width: "100%",
    height: "33rem",
  };
  return (
    <div>
      <div className="card" style={cardStyling}>
        <div className="m-2">
          <img
            src={props.imgUrl}
            alt="Bootstrap"
            height="180px"
            width="400px"
            style={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          />
        </div>
        <div className="card-body ">
          <h5
            className="card-title"
            style={{
              color: "#1D3A51",
              fontFamily: "'Poppins', semibold",
              fontWeight: "900",
            }}
          >
            {props.title}
          </h5>
          <p
            className="card-text"
            style={{
              color: "white",
              fontFamily: "'Poppins', semibold",
              height: "13rem",
            }}
          >
            {props.description}
          </p>
          <Link
            className="btn btn-sm px-3"
            to="/services"
            style={{
              borderRadius: "18px",
              backgroundColor: "#1D3A51",
              color: "white",
              fontFamily: "'Poppins', semibold",
            }}
          >
            SCOPRI DI PIÙ
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AboutUsCards;
