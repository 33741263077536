import React from "react";
import logo from "../logo.png";
import { Link } from "react-router-dom";

function Footer(props) {
  const HeadingStyle = {
    backgroundColor: "#1D3A51",
    fontFamily: "'Poppins', bold",
    color: "white",
    borderRadius: "20px",
    display: "inline-block",
    padding: "10px",
  };
  const copyrightBar = {
    backgroundColor: "#F2C232",
    textAlign: "center",
    color: "#1D3A51",
  };
  const NavTextStyle = {
    color: "#1D3A51",
    fontFamily: "'Poppins', semibold",
  };
  const NavTextStyleCurrent = {
    color: "#1D3A51",
    fontFamily: "'Poppins', bold",
    fontWeight: "bold",
  };
  const contactTextStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "10px",
    color: "#1D3A51",
    fontFamily: "'Poppins', semibold",
    textAlign: "center",
  };

  return (
    <div>
      <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-3 py-3 border-top">
        <div className="col mb-3 d-flex justify-content-center">
          <Link
            to="/"
            className="d-flex align-items-center mb-3 mx-5 link-body-emphasis text-decoration-none"
          >
            <img src={logo} alt="Logo" width="350" height="220" />
          </Link>
        </div>

        <div className="col mb-3 text-center text-md-start">
          <h6 style={HeadingStyle}>Quick Links</h6>
          <ul className="nav flex-column">
            <li className="nav-item fs-7">
              <Link
                className="nav-link active"
                aria-current="page"
                to="/"
                style={props.loc === "/" ? NavTextStyleCurrent : NavTextStyle}
              >
                {props.loc === "/" && (
                  <span style={{ fontWeight: "bold" }}>→ </span>
                )}
                Home
              </Link>
            </li>
            <li className="nav-item fs-7">
              <Link
                className="nav-link"
                to="/services"
                style={
                  props.loc === "/services" ? NavTextStyleCurrent : NavTextStyle
                }
              >
                {props.loc === "/services" && (
                  <span style={{ fontWeight: "bold" }}>→ </span>
                )}
                Servizi
              </Link>
            </li>
            <li className="nav-item fs-7">
              <Link
                className="nav-link"
                to="/aboutUs"
                style={
                  props.loc === "/aboutUs" ? NavTextStyleCurrent : NavTextStyle
                }
              >
                {props.loc === "/aboutUs" && (
                  <span style={{ fontWeight: "bold" }}>→ </span>
                )}
                Chi siamo
              </Link>
            </li>
            <li className="nav-item fs-7">
              <Link
                className="nav-link"
                to="/contactUs"
                style={
                  props.loc === "/contactUs"
                    ? NavTextStyleCurrent
                    : NavTextStyle
                }
              >
                {props.loc === "/contactUs" && (
                  <span style={{ fontWeight: "bold" }}>→ </span>
                )}
                Contattaci
              </Link>
            </li>
          </ul>
        </div>

        <div className="col mb-3 text-center text-md-start">
          <h6 style={HeadingStyle} className="text-center text-md-start">
            Contact Information
          </h6>
          <div className="d-flex flex-column align-items-center align-items-md-start">
            <div style={contactTextStyle}>
              📞 <span>Phone: +39 342 843 9658</span>
            </div>
            <div style={contactTextStyle}>
              📧 <span>Email: aicnovellara@gmail.com</span>
            </div>
            <div style={contactTextStyle}>
              📍 <span>Address: Via Andrea Costa 15 Novellara, Re 42017</span>
            </div>
            <div style={contactTextStyle}>
              🎵 <span>Tik Tok: 360 Service</span>
            </div>
            <div style={contactTextStyle}>
              📸 <span>IG: 360 Service</span>
            </div>
          </div>
        </div>
      </footer>
      <div>
        <p className="py-4" style={copyrightBar}>
          Copyright © All rights reserved (Website Developed & Managed by Surge
          Agency)
        </p>
      </div>
    </div>
  );
}

export default Footer;
