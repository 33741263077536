import React from "react";
import Services1 from "../Services1.png";
import ServicesCard from "./ServicesCard";
import card1 from "../images/1.png";
import card2 from "../images/2.png";
import card3 from "../images/3.png";
import card4 from "../images/4.png";
import card5 from "../images/5.png";
import card6 from "../images/6.png";
import card7 from "../images/7.png";
import card8 from "../images/8.png";
import card9 from "../images/9.png";
import card10 from "../images/10.png";
import card11 from "../images/11.png";
import card12 from "../images/12.png";
import card13 from "../images/13.png";
import card14 from "../images/14.png";
import card15 from "../images/15.png";
import card16 from "../images/16.png";
import card17 from "../images/17.png";
import card18 from "../images/18.png";
import card19 from "../images/19.png";
import card20 from "../images/20.png";
import card21 from "../images/21.png";
import card22 from "../images/22.png";
import card23 from "../images/23.png";
import card24 from "../images/24.png";
import card25 from "../images/25.png";
import card26 from "../images/26.png";
import card27 from "../images/27.png";
import card28 from "../images/28.png";
import card29 from "../images/29.png";
import card30 from "../images/30.png";
import card31 from "../images/31.png";
import card32 from "../images/32.png";
import card33 from "../images/33.png";
import card34 from "../images/34.png";
import card35 from "../images/35.png";
import card36 from "../images/36.png";
import card37 from "../images/37.png";
import card38 from "../images/38.png";
import card39 from "../images/39.png";
import card40 from "../images/40.png";
import card41 from "../images/41.png";
import card42 from "../images/42.png";

function Services({ addToCart }) {
  const SubHeaderStyle = {
    color: "white",
    fontFamily: "'Druk Text', sans-serif",
    fontWeight: "400",
    fontSize: "200px",
  };
  const Services = [
    {
      id: 1,
      imageUrl: card1,
      price: 0.0,
      Title: "Disoccupazione NASPI",
      Details:
        "La NASpI è un servizio di sostegno economico previsto per i lavoratori dipendenti che hanno perso involontariamente il lavoro. Con la NASpI, i disoccupati possono ricevere un'indennità per un periodo limitato.",
    },
    {
      id: 2,
      imageUrl: card2,
      price: 29.99,
      Title: "Dimissioni Volontarie",
      Details:
        "Le dimissioni volontarie sono una procedura attraverso la quale un dipendente decide di rinunciare al proprio posto di lavoro attuale. Il dipendente deve comunicare le proprie dimissioni rispettando un preavviso previsto dalla legge.",
    },
    {
      id: 3,
      imageUrl: card3,
      price: 24.4,
      Title: "ISEE Ordinario 2024",
      Details:
        "L'ISEE Ordinario 2024 è un indicatore utilizzato per valutare la situazione economica di una famiglia. È fondamentale per determinare l'accesso a diverse prestazioni e agevolazioni sociali.",
    },
    {
      id: 4,
      imageUrl: card4,
      price: 24.4,
      Title: "ISEE Universitario 2024",
      Details:
        "L'ISEE Università è un indicatore determinato da una tipologia specifica di DSU richiesta allo studente da Atenei ed Enti per il diritto allo studio per poter accedere ad agevolazioni.",
    },
    {
      id: 5,
      imageUrl: card5,
      price: 24.4,
      Title: "DID - Dichiarazione di Immediata Disponibilità",
      Details:
        "La Did online è la dichiarazione che determina formalmente l'inizio dello stato di disoccupazione di una persona.",
    },
    {
      id: 6,
      imageUrl: card6,
      price: 15.25,
      Title: "Integrazione ISEE",
      Details:
        "Il servizio permette di modificare/aggiornare i dati inseriti in una DSU già trasmessa all'INPS.",
    },
    {
      id: 7,
      imageUrl: card7,
      price: 18.3,
      Title: "Revoca Dimissioni Volontarie",
      Details:
        "Il lavoratore ha sempre la possibilità di revocare le dimissioni o la risoluzione consensuale entro 7 giorni successivi alla comunicazione.",
    },
    {
      id: 8,
      imageUrl: card8,
      price: 73.2,
      Title: "Modello Redditi Persone Fisiche Senza P.IVA",
      Details:
        "E' la dichiarazione dei redditi per le persone fisiche che hanno alcuni redditi che non permettono l'elaborazione del modello 730.",
    },
    {
      id: 9,
      imageUrl: card9,
      price: 122.0,
      Title: "Modello Redditi P.IVA Forfettaria",
      Details:
        "E' la dichiarazione dei redditi per le persone fisiche possessori di Partita IVA in regime forfettario.",
    },
    {
      id: 10,
      imageUrl: card10,
      price: 18.3,
      Title: "Naspi-Com",
      Details:
        "Il servizio NASpI Com ti consente di inviare online tutte le comunicazioni relative alla variazione della tua situazione.",
    },
    {
      id: 11,
      imageUrl: card11,
      price: 24.4,
      Title: "Assegno Unico",
      Details:
        "L'Assegno Unico e Universale è un sostegno economico alle famiglie attribuito per ogni figlio a carico fino al compimento dei 21 anni.",
    },
    {
      id: 12,
      imageUrl: card12,
      price: 24.4,
      Title: "ISEE Socio Sanitario 2024",
      Details:
        "L'ISEE Socio Sanitario è l'indicatore della situazione economica necessario per ottenere prestazioni assistenziali a domicilio o per accedere a strutture residenziali.",
    },
    {
      id: 13,
      imageUrl: card13,
      price: 6.1,
      Title: "Aggiornamento Assegno Unico e Universale",
      Details:
        "L'Aggiornamento dell'Assegno Unico permette di apportare modifiche e aggiornamenti ai dati relativi all'Assegno Unico in corso di erogazione.",
    },
    {
      id: 14,
      imageUrl: card14,
      price: 12.2,
      Title: "Estratto Conto Previdenziale",
      Details:
        "L'Estratto conto contributivo è un documento che elenca tutti i contributi effettuati all'INPS in favore del lavoratore.",
    },
    {
      id: 15,
      imageUrl: card15,
      price: 30.5,
      Title: "Assegno di inclusione",
      Details:
        "L'Assegno di inclusione sarà riconosciuto quale misura di sostegno economico e di inclusione sociale e professionale, condizionata al possesso di requisiti specifici.",
    },
    {
      id: 16,
      imageUrl: card16,
      price: 24.4,
      Title: "ISEE Minorenni 2024",
      Details:
        "L'ISEE Minorenni è uno strumento rivolto ai figli di genitori non sposati né conviventi, ed è utilizzato per fare richiesta di prestazioni sociali o bonus destinati a bambini.",
    },
    {
      id: 17,
      imageUrl: card17,
      price: 18.3,
      Title: "Busta paga colf e badanti",
      Details:
        "Servizio dedicato all'elaborazione precisa e puntuale delle buste paga mensili per le colf e badanti, gestito in modo professionale e conforme alle normative vigenti.",
    },
    {
      id: 18,
      imageUrl: card18,
      price: 50.0,
      Title: "Integrazione Modello Redditi PF",
      Details:
        "Integrazione della dichiarazione dei redditi (730 e/o Modello Redditi).",
    },
    {
      id: 19,
      imageUrl: card19,
      price: 61.0,
      Title: "Rateizzazione Cartelle Agenzia delle Entrate",
      Details:
        "Il servizio di rateizzazione dei debiti dell'Agenzia delle Entrate offre ai contribuenti la possibilità di diluire il pagamento dei loro debiti fiscali in comode rate mensili.",
    },
    {
      id: 20,
      imageUrl: card20,
      price: 24.4,
      Title: "Assegno di Maternità",
      Details:
        "E' un contributo economico, erogato dall'INPS, spettante alle madri che hanno partorito, adottato o ricevuto in affidamento preadottivo un bambino.",
    },
    {
      id: 21,
      imageUrl: card21,
      price: 30.5,
      Title: "Invalidità Civile",
      Details:
        "L'invalidità civile è una misura di assistenza sociale che garantisce assistenza sociale e mantenimento agni inabili al lavoro.",
    },
    {
      id: 22,
      imageUrl: card22,
      price: 24.4,
      Title: "Comunicazione INPS",
      Details:
        "Il servizio consente di entrare in contatto con l'INPS per sollecitare la risoluzione di problematiche riguardanti richieste che risultano ferme o in sospeso.",
    },
    {
      id: 23,
      imageUrl: card23,
      price: 48.8,
      Title: "Assunzione Colf e Badanti",
      Details:
        "La comunicazione di assunzione si presenta all'INPS entro le ore 24 del giorno precedente a quello di instaurazione del rapporto di lavoro.",
    },
    {
      id: 24,
      imageUrl: card24,
      price: 24.4,
      Title: "Bonus Asilo Nido",
      Details:
        "Il Bonus Asilo Nido è una misura di sostegno economico rivolta alle famiglie con figli fino ai 3 anni di età erogato sotto forma di rimborso delle spese sostenute fino a 3000,00€",
    },
    {
      id: 25,
      imageUrl: card25,
      price: 24.4,
      Title: "Permessi 104/92 per assistenza ai familiari disabili",
      Details:
        "La Legge n. 104/92 concede ai lavoratori con disabilità grave il diritto di usufruire di tre giorni di permesso retribuito al mese.",
    },
    {
      id: 26,
      imageUrl: card26,
      price: 24.4,
      Title: "Anticipo NASPI",
      Details:
        "La NASpI anticipata consiste nella liquidazione anticipata in un'unica soluzione dell'importo complessivo della NASpI.",
    },
    {
      id: 27,
      imageUrl: card27,
      price: 24.4,
      Title: "Dimissioni per giusta causa",
      Details:
        "Le dimissioni volontarie per giusta causa sono una procedura attraverso la quale un dipendente decide di rinunciare al proprio posto di lavoro attuale.",
    },
    {
      id: 28,
      imageUrl: card28,
      price: 61.0,
      Title: "Estratto Conto Certificato e Calcolo Pensione",
      Details:
        "Richiesta dell'estratto conto certificativo utile al calcolo della pensione e il calcolo effettivo del periodo che manca per andare in pensione.",
    },
    {
      id: 29,
      imageUrl: card29,
      price: 48.8,
      Title: "Rinnovo Permesso di Soggiorno per Lavoro Subordinato",
      Details:
        "Compilazione del modulo per il Rinnovo del permesso di soggiorno per lavoro subordinato.",
    },
    {
      id: 30,
      imageUrl: card30,
      price: 48.8,
      Title: "Lettera di assunzione colf e badanti",
      Details:
        "Servizio di redazione di una lettera di assunzione professionale e conforme alle normative vigenti, includendo tutti i dettagli necessari.",
    },
    {
      id: 31,
      imageUrl: card31,
      price: 12.2,
      Title: "Maternità Obbligatoria Post Parto",
      Details:
        "La maternità obbligatoria post parto è Il congedo di maternità obbligatoria da conseguire post parto.",
    },
    {
      id: 32,
      imageUrl: card32,
      price: 24.4,
      Title: "Indennità di Maternità Obbligatoria Dipendenti",
      Details:
        "Il congedo di maternità è il periodo di astensione obbligatoria dal lavoro riconosciuto alle lavoratrici dipendenti durante la gravidanza e il puerperio.",
    },
    {
      id: 33,
      imageUrl: card33,
      price: 24.4,
      Title: "Congedo Parentale Dipendenti",
      Details:
        "Il congedo parentale è un periodo di astensione facoltativo dal lavoro concesso ai genitori per prendersi cura del bambino nei suoi primi anni di vita.",
    },
    {
      id: 34,
      imageUrl: card34,
      price: 12.2,
      Title: "MAV trimestrale Colf e Badanti",
      Details:
        "Servizio di gestione dei bollettini MAV trimestrali per le contribuzioni previdenziali di colf e badanti.",
    },
    {
      id: 35,
      imageUrl: card35,
      price: 85.4,
      Title: "Cittadinanza Italiana per Residenza",
      Details:
        "Richiesta di cittadinanza per stranieri residenti in Italia da almeno dieci anni per gli extracomunitari, 5 anni per i cittadini comunitari.",
    },
    {
      id: 36,
      imageUrl: card36,
      price: 48.8,
      Title: "Aggiornamento Permesso di Soggiorno",
      Details:
        "Aggiornamento dei dati di qualsiasi tipo di permesso di soggiorno.",
    },
    {
      id: 37,
      imageUrl: card37,
      price: 0.0,
      Title: "Integrazione Bonus Asilo Nido",
      Details:
        "Trasmissione delle fatture di pagamento per ottenere il rimborso da parte di INPS.",
    },
    {
      id: 38,
      imageUrl: card38,
      price: 85.4,
      Title: "Cittadinanza Italiana per Matrimonio",
      Details:
        "Richiesta di cittadinanza per il coniuge straniero o apolide di cittadino italiano dopo due anni di residenza legale nel territorio della Repubblica.",
    },
    {
      id: 39,
      imageUrl: card39,
      price: 36.6,
      Title: "Variazione Colf e Badanti",
      Details:
        "Variazione del rapporto di lavoro domestico tra un collaboratore familiare e un datore di lavoro.",
    },
    {
      id: 40,
      imageUrl: card40,
      price: 24.4,
      Title: "Maternità Anticipata Dipendenti",
      Details:
        "Il congedo di maternità anticipata per le lavoratrici dipendenti durante la gravidanza.",
    },
    {
      id: 41,
      imageUrl: card41,
      price: 48.8,
      Title: "Cessazione Colf e Badanti",
      Details:
        "Procedura di cessazione del contratto di lavoro per lavoratori domestici, da comunicare all'INPS entro cinque giorni dall'evento.",
    },
    {
      id: 42,
      imageUrl: card42,
      price: 48.8,
      Title: "Rinnovo Permesso di Soggiorno per Motivi Familiari",
      Details:
        "Domanda di rinnovo del Permesso di Soggiorno per continuare a soggiornare in Italia per Motivi Familiari.",
    },
    {
      id: 43,
      imageUrl: card5,
      price: 24.4,
      Title: "Test di conoscenza della lingua Italiana A2",
      Details:
        "Test obbligatorio per il cittadino straniero che vive legalmente in Italia da più di 5 anni e intende chiedere il permesso CE per soggiornanti di lungo periodo.",
    },
    {
      id: 44,
      imageUrl: card35,
      price: 48.8,
      Title: "Rilascio Permesso di Soggiorno per soggiornanti di lungo periodo",
      Details:
        "Compilazione del modello che consente agli extracomunitari presenti sul territorio italiano con almeno 5 anni di residenza di richiedere e ottenere il permesso di soggiorno di durata di 10 anni.",
    },
    {
      id: 45,
      imageUrl: card32,
      price: 24.4,
      Title: "Dis-Coll",
      Details:
        "Il servizio permette di presentare la domanda di indennità di disoccupazione mensile 'DIS-COLL' per collaboratori coordinati e continuativi, assegnisti e dottorandi di ricerca con borsa di studio che sono inoccupati e iscritti alla Gestione Separata.",
    },
    {
      id: 46,
      imageUrl: card40,
      price: 24.4,
      Title: "Congedo Parentale Gestione Separata",
      Details:
        "Il congedo parentale è un periodo di astensione facoltativo dal lavoro concesso ai genitori naturali, adottivi o affidatari per prendersi cura del bambino nei suoi primi anni di vita e fino a 12 anni d’età per soddisfare i suoi bisogni affettivi e relazionali.",
    },
    {
      id: 47,
      imageUrl: card17,
      price: 24.4,
      Title: "Pensione Indiretta",
      Details:
        "La pensione ai superstiti è un trattamento pensionistico riconosciuto in caso di decesso dell’assicurato in favore dei familiari superstiti, nel caso in cui l’assicurato abbia perfezionato 15 anni di anzianità assicurativa e contributiva o 5 anni di cui almeno 3 nel quinquennio precedente il decesso.",
    },
    {
      id: 48,
      imageUrl: card33,
      price: 24.4,
      Title: "Indennità di Maternità Obbligatoria Gestione separata",
      Details:
        "Le lavoratrici iscritte esclusivamente alla Gestione Separata ricevono un'indennità di maternità per i 2 mesi antecedenti la data del parto ed i 3 mesi successivi direttamente dall'Inps.",
    },
    {
      id: 49,
      imageUrl: card33,
      price: 24.4,
      Title: "Indennità di Maternità Obbligatoria Autonomi",
      Details:
        "Il servizio permette di presentare la domanda per l’indennità economica durante i periodi di tutela della maternità o paternità per lavoratrici e lavoratori autonomi che sono in regola con il versamento dei contributi.",
    },
    {
      id: 50,
      imageUrl: card31,
      price: 24.4,
      Title: "Congedo Parentale Autonomi",
      Details:
        "Il servizio permette di presentare la domanda di indennità per congedo parentale per lavoratrici autonome che abbiano effettuato il versamento dei contributi relativi al mese precedente il periodo di astensione lavorativa effettiva.",
    },
    {
      id: 51,
      imageUrl: card40,
      price: 24.4,
      Title: "Maternità Anticipata Gestione Separata",
      Details:
        "La maternità anticipata per le lavoratrici iscritti alla gestione separata.",
    },
    {
      id: 52,
      imageUrl: card3,
      price: 24.4,
      Title: "Assegno Nucleo Familiare Lavoratori Dipendenti",
      Details:
        "L’Assegno per il Nucleo Familiare rivolto ai lavoratori dipendenti di aziende attive è una prestazione a sostegno del reddito per le famiglie dei lavoratori.",
    },
    {
      id: 53,
      imageUrl: card3,
      price: 24.4,
      Title: "Assegno Nucleo Familiare Lavoratori Domestici",
      Details:
        "L’Assegno per il Nucleo Familiare rivolto ai lavoratori domestici.",
    },
    {
      id: 54,
      imageUrl: card1,
      price: 24.4,
      Title: "Disoccupazione Agricola",
      Details:
        "L'indennità di disoccupazione agricola è una prestazione economica a cui hanno diritto i lavoratori agricoli dipendenti e le figure equiparate.",
    },
    {
      id: 55,
      imageUrl: card13,
      price: 48.8,
      Title: "Rinnovo Permesso di Soggiorno per Lavoro Autonomo",
      Details:
        "La domanda di rinnovo del Permesso di Soggiorno è necessaria per continuare a soggiornare in Italia per motivi di lavoro autonomo quando il permesso di soggiorno sia in scadenza o scaduto.",
    },
    {
      id: 56,
      imageUrl: card15,
      price: 85.4,
      Title: "Ricongiungimento Familiare",
      Details:
        "Il ricongiungimento familiare permette ai familiari di cittadini extracomunitari di ottenere un visto e un permesso di soggiorno per vivere in Italia. Il cittadino straniero può richiedere il ricongiungimento per la sua famiglia solo se ha un permesso di soggiorno valido per minimo un anno.",
    },
  ];
  return (
    <div>
      <div className="my-5 py-5">
        <img src={Services1} alt="Bootstrap" width="100%" />
      </div>

      <div style={{ backgroundColor: "#1D3A51" }}>
        <div
          className="container py-5"
          style={{
            width: "83%",
            maxWidth: "100%",
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <div style={SubHeaderStyle}>
            <h1>DO YOU NEED SUPPORT?</h1>
          </div>
          <div className="row">
            {Services.map((service) => (
              <div
                key={service.id}
                className="col-6 col-md-4 my-4" // col-12 for small screens, col-md-4 for medium and above
              >
                <ServicesCard
                  id={service.id}
                  imgUrl={service.imageUrl}
                  title={service.Title}
                  description={service.Details}
                  price={service.price}
                  onAddToCart={addToCart}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
