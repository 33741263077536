import React, { useState } from "react";
import "./Cart.css";
import StripeCheckout from "react-stripe-checkout";
import UserInfoForm from "./UserInfoForm";

const Cart = ({ cartItems, removeFromCart }) => {
  const [cartVisible, setCartVisible] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [paymentStage, setPaymentStage] = useState("cart");

  const toggleCart = () => {
    setCartVisible(!cartVisible);
  };

  const handleUserInfoSubmit = (info) => {
    setUserInfo(info);
    setPaymentStage("payment");
  };

  const TextStyle = {
    textAlign: "left",
    fontFamily: "'Poppins', semibold",
    fontWeight: "900",
    color: "#1D3A51",
    fontSize: "20px",
  };

  const TextStyleHeader = {
    fontFamily: "'Druk Text', sans-serif",
    fontWeight: "300",
    color: "white",
  };

  const HeaderStyle = {
    backgroundColor: "white",
    textAlign: "left",
    fontFamily: "'Poppins', semibold",
    fontWeight: "900",
    color: "#1D3A51",
    fontSize: "20px",
  };

  const MakePayment = (token) => {
    const body = {
      token,
      userInfo,
      product: {
        price: totalPrice,
        name: cartItems.map((item) => item.Title).join(", "),
      },
    };

    const headers = {
      "Content-Type": "application/json",
    };

    return fetch(`http://localhost:8282/payment`, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.log("RESPONSE ", response);
        const status = { response };
        console.log("STATUS", status);
      })
      .catch((error) => console.log(error));
  };

  const totalPrice = cartItems
    .reduce((sum, item) => sum + item.price, 0)
    .toFixed(2);

  return (
    <>
      <button className="cart-button" onClick={toggleCart}>
        Cart ({cartItems.length})
      </button>

      {cartVisible && (
        <div className="cart-card">
          {paymentStage === "cart" && (
            <>
              <h3 style={TextStyleHeader}>YOUR CART</h3>
              <div className="row">
                <div className="col-1" style={TextStyle}></div>
                <div
                  className="col-8 text-left d-flex justify-content-start"
                  style={TextStyle}
                >
                  SERVICES
                </div>
                <div
                  className="col-2 text-start d-flex justify-content-start"
                  style={TextStyle}
                >
                  PRICE
                </div>
                <div className="col-1" style={TextStyle}></div>
              </div>
              <div className="row justify-content-start">
                {cartItems.map((item, index) => (
                  <div key={item.id} className="d-flex align-items-center">
                    <div className="col-1 text-start d-flex justify-content-start">
                      {index + 1}
                    </div>
                    <div className="col-8 text-start d-flex justify-content-start">
                      {item.Title}
                    </div>
                    <div className="col-2 text-start d-flex justify-content-start">
                      €{item.price.toFixed(2)}
                    </div>
                    <div className="col-1 text-start d-flex justify-content-start">
                      <button
                        onClick={() => removeFromCart(item.id)}
                        className="btn btn-sm btn-danger ml-2 d-flex align-items-center"
                      >
                        X
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col-1" style={TextStyle}></div>
                <div
                  className="col-8 text-left d-flex justify-content-start"
                  style={TextStyle}
                >
                  TOTAL PRICE
                </div>
                <div
                  className="col text-left d-flex justify-content-start"
                  style={HeaderStyle}
                >
                  {totalPrice} €
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button
                    onClick={toggleCart}
                    style={{
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                  >
                    Close
                  </button>
                </div>
                <div className="col">
                  <button
                    onClick={() => setPaymentStage("userInfo")}
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            </>
          )}

          {paymentStage === "userInfo" && (
            <UserInfoForm
              onSubmit={handleUserInfoSubmit}
              onBack={() => setPaymentStage("cart")}
            />
          )}

          {paymentStage === "payment" && (
            <div className="row">
              <div className="col">
                <button
                  onClick={() => setPaymentStage("userInfo")}
                  style={{
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                >
                  Back
                </button>
              </div>
              <div className="col">
                <StripeCheckout
                  stripeKey={process.env.REACT_APP_KEY}
                  token={MakePayment}
                  name="CartCheckout"
                  amount={totalPrice * 100}
                >
                  <button
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                  >
                    Pay Now
                  </button>
                </StripeCheckout>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;
