import React from "react";
import T4 from "../T4.png";
import card1 from "../aboutUsCard1.jpg";
import card2 from "../aboutUsCard2.jpg";
import card3 from "../aboutUsCard3.webp";
import image1 from "../222.png";
import image2 from "../111.png";
import image3 from "../333.png";
import image4 from "../444.png";
import image5 from "../555.png";
import AboutUsPoints from "./AboutUsPoints";
import AboutUsCards from "./AboutUsCards";

function AboutUs() {
  const SubHeaderStyle = {
    color: "white",
    fontFamily: "'Druk Text', sans-serif",
    fontWeight: "400",
    fontSize: "200px",
    textAlign: "center", // Added center alignment while preserving original style
  };
  const Points = [
    {
      id: 1,
      imageUrl: image1,
      Details:
        "Il Centro di Assistenza Fiscale (CAF) e il Patronato, affiliati all'Associazione Italiana Coltivatori (AIC), offrono servizi personalizzati per cittadini e non cittadini.",
    },
    {
      id: 2,
      imageUrl: image2,
      Details:
        "Grazie all'affiliazione con l'AIC, forniscono servizi efficienti e aggiornati in risposta ai cambiamenti legislativi, offrendo assistenza per dichiarazioni fiscali, previdenza sociale, diritti dei lavoratori, pensioni e molto altro.",
    },
    {
      id: 3,
      imageUrl: image3,
      Details:
        "Sono particolarmente focalizzati sull'aiutare gli stranieri a navigare tra le normative italiane e accedere ai servizi e benefici a cui hanno diritto.",
    },
    {
      id: 4,
      imageUrl: image4,
      Details:
        "L'ufficio è dedicato a semplificare la burocrazia, migliorando la vita delle persone attraverso fiducia, trasparenza e professionalità, con un focus sulla persona.",
    },
    {
      id: 5,
      imageUrl: image5,
      Details:
        " L'ufficio accoglie chiunque cerchi orientamento o supporto e si impegna a fornire passione e professionalità per soddisfare le esigenze più diverse.",
    },
  ];
  const Services = [
    {
      id: 1,
      imageUrl: card1,
      Title: "Assistenza Fiscale e Reddituale",
      Details:
        "Servizi volti ad aiutare individui e famiglie con i processi fiscali e le dichiarazioni dei redditi. Dalla presentazione dei modelli fiscali annuali come il modello 730 alla ottenimento delle certificazioni ISEE, questi servizi garantiscono la corretta documentazione finanziaria e l'accesso ai benefici.",
    },
    {
      id: 2,
      imageUrl: card2,
      Title: "Benefici Lavorativi e Sociali",
      Details:
        "Forniscono supporto fondamentale per chi affronta disoccupazione, necessità di assistenza familiare o programmi di welfare sociale. Includono aiuto per la richiesta di indennità di disoccupazione, indennità di maternità e domande per l'Assegno Unico per figli a carico.",
    },
    {
      id: 3,
      imageUrl: card3,
      Title: "Servizi Domestici e Vari",
      Details:
        "Focalizzati su attività amministrative e servizi legati alla casa, questa categoria assiste nell'assunzione di lavoratori domestici, nell'ottenimento delle comunicazioni INPS e nel calcolo dei diritti pensionistici. Offrono anche servizi come il rinnovo dei permessi di soggiorno e la richiesta di bonus per l'iscrizione alla scuola materna.",
    },
  ];

  const HeaderStyle = {
    color: "#1D3A51",
    fontFamily: "'Druk Text', sans-serif",
    textAlign: "center", // Added center alignment while preserving original left alignment
    fontWeight: "900",
    fontSize: "55px",
  };
  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center text-center text-md-start">
          <div className="col-md-6">
            <img src={T4} alt="Bootstrap" width="100%" />
          </div>
          <div className="col-md-6 my-5 py-5 text-center text-md-start">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h1 style={HeaderStyle}>CHI SIAMO?</h1>
          </div>
        </div>
        <div className="row">
          {Points.map((Point) => (
            <div key={Point.id} className="col-12 text-center my-4">
              <AboutUsPoints imgUrl={Point.imageUrl} Desc={Point.Details} />
            </div>
          ))}
        </div>
      </div>
      <div style={{ backgroundColor: "#1D3A51" }}>
        <div className="container py-5">
          <div style={SubHeaderStyle} className="text-center">
            <h1>HAI BISOGNO DI SUPPORTO?</h1>
          </div>
          <div className="row">
            {Services.map((service) => (
              <div key={service.id} className="col-md-4 text-center my-4">
                <AboutUsCards
                  imgUrl={service.imageUrl}
                  title={service.Title}
                  description={service.Details}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
