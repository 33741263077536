import React from "react";
import { Link } from "react-router-dom";
import T1 from "../T1.png"; // Adjust path accordingly
import "./Homepage.css"; // Import the CSS file

function Homepage() {
  return (
    <div className="container my-5">
      <div className="row justify-content-center text-center text-md-start">
        <div className="col-12 col-md-6 my-5 py-5 d-flex flex-column justify-content-center align-items-center align-items-md-start">
          <h1 className="header-style">SUPPORTO FINANZIARIO COMPLETO</h1>
          <hr className="w-100" />
          <h1 className="subheader-style">CENTRO MONDIALE</h1>
          <div className="d-flex justify-content-center justify-content-md-start w-100">
            <Link
              className="btn btn-lg px-5"
              to="/services"
              style={{
                borderRadius: "18px",
                backgroundColor: "#1D3A51",
                color: "white",
              }}
            >
              I nostri servizi
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-center">
          <img
            src={T1}
            alt="Bootstrap"
            className="img-fluid"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
