import React from "react";
import ConatctCard from "./ContactCard";
import Address from "../Address.png";
import Location from "../Location.png";
import Mail from "../Mail.png";
import ContactUs from "../ContactUs.png";
import { Link } from "react-router-dom";

function ContactInfo() {
  const HeaderStyle = {
    color: "#1D3A51",
    fontFamily: "'Poppins', bold",
    textAlign: "left",
    fontWeight: "900",
  };
  const SubHeaderStyle = {
    color: "#F2C232",
    fontFamily: "'Poppins', bold",
    textAlign: "left",
    fontWeight: "900",
  };
  return (
    <div className="container">
      <div className="my-5">
        <img src={ContactUs} alt="Bootstrap" className="img-fluid w-100" />
      </div>
      <div className="row py-5 my-5">
        <div className="col-md-1"></div>
        <div className="col-md-5 text-center text-md-start">
          <ConatctCard />
        </div>
        <div className="col-md-5 my-5 text-center text-md-start">
          <h1 style={HeaderStyle} className="text-center text-md-start">
            CONTATTACI
          </h1>
          <p
            style={{
              color: "#1D3A51",
              fontFamily: "'Poppins'",
              textAlign: "center",
              fontWeight: "100",
            }}
            className="text-center text-md-start"
          >
            Siamo lieti di sentirti! Non esitare a contattarci. Il nostro team è
            qui per assisterti con qualsiasi domanda.
          </p>
          <h3 style={HeaderStyle} className="text-center text-md-start">
            JUNAID KHAN
          </h3>

          {/* Address Section */}
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-md-start mb-3">
            <div className="me-sm-2 mb-2 mb-sm-0">
              <Link className="navbar-brand" to="/">
                <img src={Address} alt="Bootstrap" width="30" />
              </Link>
            </div>
            <div className="me-sm-2 mb-2 mb-sm-0">
              <h2 style={SubHeaderStyle} className="m-0">
                INDIRIZZO
              </h2>
            </div>
            <div>
              <p
                style={{
                  color: "#1D3A51",
                  fontFamily: "'Poppins'",
                  textAlign: "center",
                  fontWeight: "100",
                }}
                className="m-0 text-center text-sm-start"
              >
                Via Andrea Costa 15 Novellara, Re 42017
              </p>
            </div>
          </div>

          {/* Phone Section */}
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-md-start mb-3">
            <div className="me-sm-2 mb-2 mb-sm-0">
              <Link className="navbar-brand" to="/">
                <img src={Location} alt="Bootstrap" width="35" />
              </Link>
            </div>
            <div className="me-sm-2 mb-2 mb-sm-0">
              <h2 style={SubHeaderStyle} className="m-0">
                TELEFONO
              </h2>
            </div>
            <div>
              <p
                style={{
                  color: "#1D3A51",
                  fontFamily: "'Poppins'",
                  textAlign: "center",
                  fontWeight: "100",
                }}
                className="m-0 text-center text-sm-start"
              >
                +39 342 843 9658
              </p>
            </div>
          </div>

          {/* Email Section */}
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-md-start mb-3">
            <div className="me-sm-2 mb-2 mb-sm-0">
              <Link className="navbar-brand" to="/">
                <img src={Mail} alt="Bootstrap" width="40" />
              </Link>
            </div>
            <div className="me-sm-2 mb-2 mb-sm-0">
              <h2 style={SubHeaderStyle} className="m-0">
                INDIRIZZO EMAIL
              </h2>
            </div>
            <div>
              <p
                style={{
                  color: "#1D3A51",
                  fontFamily: "'Poppins'",
                  textAlign: "center",
                  fontWeight: "100",
                }}
                className="m-0 text-center text-sm-start"
              >
                Aicnovellara@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
