import React from "react";

function ServicesCard({ id, imgUrl, title, description, price, onAddToCart }) {
  const cardStyling = {
    backgroundColor: "#F2C232",
    maxWidth: "540px",
    height: "100%",
  };

  const priceCol = {
    color: "#F2C232",
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "900",
  };

  const handleBuyNow = () => {
    onAddToCart({
      id,
      price,
      Title: title,
      Details: description,
    });
  };

  return (
    <div className="card h-100" style={cardStyling}>
      <div className="row g-0 h-100 flex-row">
        <div className="col-3 col-md-5 d-flex justify-content-center align-items-center p-2">
          <img
            src={imgUrl}
            alt={title}
            className="img-fluid"
            style={{
              borderRadius: "10px",
              maxWidth: "100%",
              maxHeight: "200px",
              width: "auto",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </div>

        <div className="col-9 col-md-7 h-100">
          <div
            className="bg-white h-100 d-flex flex-column"
            style={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <div className="p-3 flex-grow-1">
              <h5 className="card-title mb-2">{title}</h5>
              <p
                className="card-text mb-3"
                style={{
                  maxHeight: "120px",
                  overflowY: "auto",
                }}
              >
                {description}
              </p>
              <h2 style={priceCol}>€{price}</h2>
            </div>
            <button
              onClick={handleBuyNow}
              className="btn py-2"
              style={{
                borderRadius: 10,
                backgroundColor: "#1D3A51",
                color: "white",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "1.2rem",
                width: "100%",
              }}
            >
              BUY NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesCard;
