import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify styles

function ContactCard() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_m1h68wp", // Replace with your EmailJS Service ID
        "template_382tdko", // Replace with your EmailJS Template ID
        formData,
        "bdihQiukEik-FyzxX" // Replace with your EmailJS Public Key
      )
      .then(
        (result) => {
          toast.success("🎉 Message sent successfully!", {
            position: "top-center",
            autoClose: 3000,
            style: {
              backgroundColor: "#1D3A51", // Red
              color: "#fff",
            },
          });
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          }); // Reset form fields
          setLoading(false);
        },
        (error) => {
          console.error("Error sending message:", error);
          toast.error("❌ Failed to send message. Please try again later.", {
            style: {
              backgroundColor: "#1D3A51", // Red
              color: "#fff",
            },
            position: "top-center",
            autoClose: 3000,
          });
          setLoading(false);
        }
      );
  };

  return (
    <div>
      <div
        className="card"
        style={{
          maxWidth: "500px",
          backgroundColor: "#1E3B51",
          borderRadius: "10px",
        }}
      >
        <div className="card-body py-5 px-4">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="subject"
                className="form-control"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <textarea
                name="message"
                className="form-control"
                rows="7"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="d-grid">
              <button
                type="submit"
                className="btn btn-warning btn-lg"
                style={{ borderRadius: "25px", color: "white" }}
                disabled={loading}
              >
                {loading ? "Sending..." : "Invia messaggio"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer /> {/* Toastify container */}
    </div>
  );
}

export default ContactCard;
