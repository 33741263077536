import React, { useState } from "react";
import logo from "../logo.png";
import { Link } from "react-router-dom";
import "./NavBar.css";

function NavBar(props) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const NavTextStyle = {
    color: "white",
    fontFamily: "'Poppins', semibold",
  };

  const NavTextStyleHover = {
    backgroundColor: "#1D3A51",
    fontFamily: "'Poppins', semibold",
    color: "white",
    borderRadius: "15px",
  };

  const NavBarStyle = {
    backgroundColor: "#F2C232",
    color: "white",
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    padding: "10px",
  };

  return (
    <div className="row">
      <div className="col-12">
        <nav className="navbar navbar-expand-lg py-3" style={NavBarStyle}>
          <div className="container-fluid d-flex align-items-center">
            <Link className="navbar-brand me-auto" to="/">
              <img src={logo} alt="Bootstrap" width="100" height="63" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded={!isNavCollapsed ? true : false}
              aria-label="Toggle navigation"
              onClick={handleNavCollapse}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
              id="navbarNav"
              style={{
                backgroundColor: "#F2C232",
                borderRadius: "15px",
                padding: "10px",
              }}
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item mx-5 fs-4">
                  <Link
                    className="nav-link active px-3"
                    aria-current="page"
                    to="/"
                    style={props.loc === "/" ? NavTextStyleHover : NavTextStyle}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item mx-5 fs-4">
                  <Link
                    className="nav-link px-3"
                    to="/services"
                    style={
                      props.loc === "/services"
                        ? NavTextStyleHover
                        : NavTextStyle
                    }
                  >
                    Servizi
                  </Link>
                </li>
                <li className="nav-item mx-5 fs-4">
                  <Link
                    className="nav-link px-3"
                    to="/aboutUs"
                    style={
                      props.loc === "/aboutUs"
                        ? NavTextStyleHover
                        : NavTextStyle
                    }
                  >
                    Chi siamo
                  </Link>
                </li>
                <li className="nav-item mx-5 fs-4">
                  <Link
                    className="nav-link px-3"
                    to="/contactUs"
                    style={
                      props.loc === "/contactUs"
                        ? NavTextStyleHover
                        : NavTextStyle
                    }
                  >
                    Contattaci
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NavBar;
