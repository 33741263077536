import React, { useState } from "react";

const UserInfoForm = ({ onSubmit, onBack, initialData = {} }) => {
  const [formData, setFormData] = useState({
    firstName: initialData.firstName || "",
    lastName: initialData.lastName || "",
    email: initialData.email || "",
    phone: initialData.phone || "",
    // Removed address object
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = ["firstName", "lastName", "email", "phone"];

    const isValid = requiredFields.every(
      (field) => formData[field].trim() !== ""
    );

    if (isValid) {
      onSubmit(formData);
    } else {
      alert("Please fill in all required fields");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="user-info-form p-3">
      <h3 className="mb-4">Customer Information</h3>

      <div className="row">
        <div className="col-6 form-group">
          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>

        <div className="col-6 form-group">
          <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>

        <div className="col-6 form-group">
          <label>Phone</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          <button
            type="button"
            onClick={onBack}
            className="btn btn-secondary w-100"
          >
            Back to Cart
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Continue to Payment
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserInfoForm;
