import React from 'react'

function AboutUsPoints(props) {
  const SubHeaderStyle={
    color:'#1D3A51' ,fontFamily:"'Poppins'",textAlign:"left",fontWeight:"400", fontSize:"100px"
  }
  return (
    <div className='container'>
      <div className="row">
      <div className="col-md-1"><img src={props.imgUrl} alt="Bootstrap" width="100px"/></div>
      <div className="col-md-11 d-flex align-items-center" style={SubHeaderStyle}><h3>{props.Desc}</h3></div>
      </div>
      
      
    </div>
  )
}

export default AboutUsPoints
